<template>
    <div class="contain-box full-height">
        <CardBox>
            <template #tool-title>
                <div class="tab-bar"></div>
            </template>
            <template #tool-bar>
                <Space :size="20">
                    <Search
                        @search="search"
                        :resetVal="resetVal"
                        :placeholder="
                            store.getters.isRoleType(Role.Admin)
                                ? `${t('resource.spaceName')}/${t('resource.creator')}/${t('user.team')}`
                                : `${t('resource.spaceName')}/${t('resource.creator')}`
                        "
                        :width="260" />
                </Space>
            </template>
            <template #contain>
                <Table
                    :class="['user-table', { 'hide-page-list': pageConf.total <= pageConf.pageSizeOptions[0] }]"
                    :pagination="pageConf"
                    :data="pageConf.tableData"
                    :scroll="{ y: pageConf.height }"
                    :bordered="false"
                    :stripe="true">
                    <template #columns>
                        <TableColumn :title="t('resource.spaceName')" data-index="name">
                            <template v-slot:title>
                                <span style="margin-left: 38px">
                                    {{ t('resource.spaceName') }}
                                </span>
                            </template>
                            <template #cell="{ record }">
                                <div class="cell">
                                    <SvgIcon
                                        v-if="!record.trial"
                                        :name="$store.getters.isEnglish ? 'trial-en' : 'trial'"
                                        :color="valiateExpireTime(record.expireTime) ? '#666' : '#2b32c9'"
                                        :size="28" />
                                    <SvgIcon v-else :color="valiateExpireTime(record.expireTime) ? '#666' : '#2b32c9'" :size="28" />
                                    <span>{{ record.name }}</span>
                                </div>
                            </template>
                        </TableColumn>
                        <TableColumn :title="t('resource.creator')" data-index="createBy" ellipsis>
                            <template #cell="{ record }">
                                <span :title="record.createBy">{{ record.createBy }}</span>
                            </template>
                        </TableColumn>
                        <TableColumn v-if="store.getters.isRoleType(Role.Admin)" :title="t('user.team')" data-index="team" ellipsis>
                            <template #cell="{ record }">
                                <span :title="record.teamName">{{ record.teamName }}</span>
                            </template>
                        </TableColumn>
                        <TableColumn :title="t('resource.createTime')" data-index="createTime">
                            <template #cell="{ record }">
                                <span>{{ moment(record.createTime).format('YYYY-MM-DD HH:mm') }}</span>
                            </template>
                        </TableColumn>
                        <TableColumn :title="t('operation')" :width="110">
                            <template #cell="{ record }">
                                <Space :size="10">
                                    <SvgIcon name="delete" :tip="t('delete')" color="#2b32c9" hoverColor="#2bc954" :size="20" @click="deleteFunc(record)" />
                                </Space>
                            </template>
                        </TableColumn>
                    </template>
                </Table>
            </template>
        </CardBox>
        <Modal width="500px" modalClass="space-modal" v-model:visible="modalVisible" @close="handleClose" :mask-closable="false">
            <template #title>
                <p class="modal-title">{{ t('deleteSpace') }}</p>
            </template>
            <div>
                <Form ref="formDom" :model="formData" :rules="formRule" layout="vertical" autocomplete="off">
                    <p class="space-tip">{{ t('tip.deleteSpaceTip', { name: formData.originName }) }}</p>
                    <FormItem field="spaceName" :validate-trigger="['blur']">
                        <Input
                            v-model="formData.spaceName"
                            @blur="formData.spaceName = formData.spaceName.trim()"
                            spellcheck="false"
                            :placeholder="formData.originName" />
                    </FormItem>
                </Form>
            </div>
            <template #footer>
                <Button class="btn-fill" type="primary" :disabled="!formData.spaceName" html-type="submit" @click="handleSubmit" :loading="loading">
                    {{ t('ok') }}
                </Button>
            </template>
        </Modal>
    </div>
</template>

<script>
import CardBox from '@/components/CardBox.vue'
import Search from '@/components/Search.vue'
import useWindowResize from '@/hooks/useWindowResize'
import { Form } from '@arco-design/web-vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { Role } from '@/store'
import { valiateExpireTime } from '@/utils/utils'
import { defineComponent, reactive, ref, inject, watch } from 'vue'
export default defineComponent({
    components: {
        CardBox,
        Search
    },
    setup() {
        const t = inject('$t')
        const http = inject('$http')
        const winData = useWindowResize()
        const moment = inject('$moment')
        const route = useRoute()
        const router = useRouter()
        const store = useStore()
        const resetVal = ref(true)
        const msg = inject('$message')
        const modalVisible = ref(false)
        const formDom = ref(null || Form)
        const loading = ref(false)
        watch(
            () => winData.height,
            (val) => {
                pageConf.height = val - 360
            }
        )
        const pageConf = reactive({
            teamName: '',
            tableData: [],
            showTotal: true,
            showPageSize: true,
            hideOnSinglePage: false,
            pageSizeOptions: [30, 60, 100],
            current: 1,
            pageSize: 30,
            total: 0,
            height: 570,
            onChange: (data) => {
                pageConf.current = data
                getData()
            },
            onPageSizeChange: (data) => {
                pageConf.current = 1
                pageConf.pageSize = data
                getData()
            }
        })
        const search = (val) => {
            pageConf.current = 1
            pageConf.teamName = val
            getData()
        }
        const getData = () => {
            const params = {
                page: pageConf.current - 1,
                size: pageConf.pageSize,
                content: pageConf.teamName
            }
            let action = ''
            if (store.getters.isRoleType(Role.Admin)) {
                action = 'getWorkspaceList'
            } else {
                params.teamId = store.state.userData.teamId
                action = 'getLeaderWorkspaceList'
            }
            http.account[action](params).then(({ data }) => {
                pageConf.tableData = data.data
                pageConf.total = data.total
                document.querySelector('.arco-table-body').scrollTop = 0
            })
        }
        getData()

        const formData = reactive({
            spaceName: '',
            originName: '',
            id: ''
        })
        const deleteFunc = (item) => {
            modalVisible.value = true
            formData.spaceName = ''
            formData.id = item.id
            formData.originName = item.name
        }
        const formRule = {
            spaceName: [
                {
                    validator: (val, callback) => {
                        if (!val) {
                            callback(t('holder.inputHolder', { key: t('resource.spaceName') }))
                        } else if (val !== formData.originName) {
                            callback(t('tip.spaceNameDifferentTip'))
                        } else {
                            callback()
                        }
                    }
                }
            ]
        }
        const handleClose = () => {
            loading.value = false
            formDom.value.resetFields()
            modalVisible.value = false
        }
        const handleSubmit = () => {
            formDom.value.validate((errors) => {
                if (!errors) {
                    const action = store.getters.isRoleType(Role.Admin) ? 'deleteSpace' : 'deleteLeaderSpace'
                    http.account[action](formData.id).then(() => {
                        pageConf.current = 1
                        getData()
                        msg.info(t('delSucc'))
                        modalVisible.value = false
                        loading.value = false
                    })
                }
            })
        }

        return {
            pageConf,
            resetVal,
            store,
            router,
            route,
            t,
            Role,
            formRule,
            formData,
            search,
            getData,
            deleteFunc,
            formDom,
            modalVisible,
            handleClose,
            handleSubmit,
            valiateExpireTime,
            loading,
            moment
        }
    }
})
</script>

<style lang="less" scoped>
.contain-box {
    .user-table {
        padding: 0 60px;
        margin-top: 30px;
    }
    .cell {
        align-items: center;
        display: flex;
        white-space: pre-wrap;
        .icon-btn {
            padding-right: 10px;
        }
    }
}
.space-modal {
    .modal-title {
        font-size: 18px;
        font-weight: 700;
        color: #666666;
    }
    .space-tip {
        text-align: center;
        white-space: pre-wrap;
        color: #666666;
        font-size: 14px;
        padding: 0 40px 16px;
    }
}
</style>
